.projects{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
    animation-name: fade;
    animation-duration: 1s;
    /* background-image: url('../../assets/users\ metrics.png'); */
    /* opacity: 0.05; */
    /* overflow: hidden; */
    
}

.arrow-active {
    animation-name: arrow;
    animation-duration: 0.4s;
    color: #14EB97;
}

@keyframes arrow {
    from {
        transform: rotate(0);
        color: white;
    }
    to {
        transform: rotate(90deg);
        color: #14EB97;
    }
}

.proj-list {
    display: flex;
    flex-direction: column;
}

.proj-bullets {
    /* height: 100%;
    width: fit-content; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    position: absolute;
    left: 50px;
    font-size: 24pt;
}

.outer {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.active {
    height: 20px;
    width: 20px;
    border: 2px solid #14EB97;

}

.inner {
    margin: 0;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: white;
}

.active-inner {
    margin: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #14EB97;
}

.arrow {
    cursor: pointer;
}

.arrow:hover {
    color: #14EB97 !important;
}

/* .arrow {
    font-size: 18pt;
    margin: 5px;
    color: white;
    transform: 0.3s ease-in-out;
    

}

.active-arrow {
    transform: rotate(90deg);
    color:  #14EB97;
} */


@media (max-width: 860px) {
    .proj-bullets {
        left: 10px;
        font-size: 18pt;
    }

    .proj-list {
        height: 100%;
    }

    .pr-lang-icons {
        font-size: 25pt !important;
    }
}

