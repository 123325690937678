.button-container {
    padding: 10px 20px;
    width: fit-content;
    border-radius: 0.2rem;
    transition: 0.3s;
    color: black;
    margin-right: 20px;
    cursor:pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 660px) {
    .button-container {
        font-size: 9pt;
    }
}


/* .button-container:hover {
    box-shadow: -5px -5px 20px black, 5px 5px 20px grey;
    border: 0px;



} */