.menu{
    position: absolute;
    top: 1%;
    right: 4%;
    font-weight: bolder;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-icons {
    margin-right: 20px;
    font-size: 22pt;
    display: flex;

}

.menu-icons span a {
    text-decoration: none;
    color: white;
    color: #14EB97;
    margin-right: 20px;
    font-size: 14pt;
    transition: 0.5s ease-out;
}

.menu-icons span a:hover {

    color: white;

}

.menu-icons span {
    margin-left: 30px;
}
.other-menu {
    transition: 0.1s ease-in
}
.other-menu:hover {
    color: #14EB97 !important;
}
@media (max-width: 660px) {
    .menu-icons {
        flex-direction: column;
        margin-right: 0;
        font-size: 15pt;
        align-items: flex-end;
    }
    .menu-icons a {
        text-align: center;
    }
    .sc-icons {
        font-size: 10pt;
    }
}