body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #121312;
    height: 100%;
    width: 100%;
}
html {
    height: 100%;
    width: 100%;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
} */

#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    } 
}
