.logo {
    position: absolute;
    top: 1%;
    left: 4%;
    font-weight: bolder;
    color: white;
    font-size: 22pt;

}

@media (max-width: 660px) {
.logo {
    font-size: 15pt;
}
}