.proj-entry-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    animation-name: fade;
    animation-duration: 1s;
}

.proj-text {
    width: 90%;
    /* padding-right: 10px; */
    /* flex: 1 1 0; */
}

.proj-text p {
    width: 100%;
}

.proj-image {
    width: 90%;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: row;

    /* flex: 1 1 0; */
}

.proj-image img {
    /* width: 80%; */
    border-radius: 1rem;
    position: relative;
    /* box-shadow: 10px 10px 40px #121312, -10px -10px 40px #121312; */
    overflow: hidden;
    cursor:pointer;

}

.proj-lang-icons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup img {
    max-width: 80%;
    max-height: 80%;
}

.proj-visit {
    overflow: hidden;
    background-color: #14EB97;
    border-radius: 2rem;
    margin-left: 30px;
    width: 30px;
    height: 30px;
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* color: black; */
}

.proj-visit .icon{
    position: relative;
    top: 1%;
    left: 1%;
}

.proj-visit a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.proj-visit:hover {
    width: 140px;
    /* box-shadow: inset 0 0 3px 1px rgba(0, 0, 0, 1); */

}

.visit-text {
    display: none;
    color: #121312;
    /* color: whitesmoke; */
    margin-left: 10px;
    font-weight: bold;
    animation-name: fade;
    animation-duration: 1.3s;
}

.proj-visit:hover .visit-text {
    display: inline-block;
}

.proj-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}

.proj-header h1 {
    width: fit-content;
}

@media (max-width: 1024px) {
    .proj-entry-container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .proj-lang-icons {
        flex-wrap: wrap;
        font-size: 8pt !important;
    }

    .proj-image {
        padding: 30px 0;
        max-height: 100%;
    }

    .proj-visit:hover {
        width: 30px;
    }
  }

  @media (max-width: 660px) {
    .proj-entry-container {
        justify-content: flex-start;
        margin-bottom: 50px;
        align-items: flex-end;
    }
    
  }