.App {
    text-align: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(assets/nnnoise-2.svg);
    background-size: auto;
    backdrop-filter: blur(100px);
    
    /* padding-left: 5%;
    padding-right: 5%; */
    /* margin-left: 10%;
    margin-right: 10%; */
    
    /* overflow: hidden; */
}
