.home {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    margin-left: 10%;
    margin-right: 10%;
    /* margin-top: 1%; */
    text-align: left;
    animation-name: fade;
    animation-duration: 1s;
    /* overflow: hidden; */
    
}

.welcome-text {
    flex: 1 1 0;
}

.profile {
    flex: 1 1 0;
    text-align: center;
    /* padding-left: 80px; */
}

h1 {
    margin: 0;
    font-size: 37pt;
}

h3 {
    margin-top: 0;
}

.profile img {
    width: 45%;
    padding-left: 60px;
    /* position: relative; */
    /* right:80px; */
}

.buttons {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}


@media (max-width: 1024px) {
    .home {
        display: flex;
        flex-direction: column-reverse;

    }
    .profile {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }


  }

@media (max-width: 960px) {
    .profile {
        display: none;
    }

    .welcome-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    h1 {
        font-size: 25pt;
    }
     h3 {
        font-size: 13pt;
     }

     p {
        font-size: 11pt;
     }
}