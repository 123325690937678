.circle {
    border-radius: 50%;

}

.green {
    background-color: #14EB97;
    position: relative;
    width: 300px;
    height: 300px;

}
.purple {
    background-color: #9714EB;
    position: relative;
    width: 500px;
    height: 500px;
}

.circles-container {
    position: absolute;
    top: 30%;
    left: 15%;
    width: 100%;
    /* transform: translate(-50%); */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    

}
.bg-container {
    position: relative;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(10px);
    overflow: hidden;
    

}

@media (max-width: 950px) {
    .circles-container {
        left: 0%;
        top: 13%;
    }

    .purple {
        width: 300px;
        height: 300px;
    }
    .green {
        width: 250px;
        height: 250px;
    }
}